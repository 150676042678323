import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/templates": [16],
		"/[slug]": [4,[2]],
		"/[slug]/about": [5,[2]],
		"/[slug]/contact": [6,[2]],
		"/[slug]/news": [7,[2]],
		"/[slug]/post/[id]": [8,[2]],
		"/[slug]/products": [9,[2]],
		"/[slug]/products/[id]": [10,[2]],
		"/[slug]/services": [11,[2]],
		"/[slug]/services/[id]": [12,[2]],
		"/[slug]/team": [13,[2]],
		"/[slug]/works": [14,[2]],
		"/[slug]/works/[id]": [15,[2]],
		"/[...path]": [3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';